:root {
  --stf-font-family: 'Ubuntu', sans-serinf; }

:root {
  --stf-white: #fff;
  --stf-black: #000; }

:root {
  --stf-gray-50: #eaf3ff;
  --stf-gray-100: #ced8e4;
  --stf-gray-200: #b1becd;
  --stf-gray-300: #94a3b8;
  --stf-gray-400: #7689a2;
  --stf-gray-500: #5d7089;
  --stf-gray-600: #47576b;
  --stf-gray-700: #323e4e;
  --stf-gray-800: #1d2531;
  --stf-gray-900: #040d17; }

:root {
  --stf-red-50: #ffe4e4;
  --stf-red-100: #feb5b6;
  --stf-red-200: #f88687;
  --stf-red-300: #f45757;
  --stf-red-400: #f02928;
  --stf-red-500: #d7110f;
  --stf-red-600: #a80b0b;
  --stf-red-700: #790607;
  --stf-red-800: #4a0203;
  --stf-red-900: #1f0000; }

:root {
  --stf-orange-50: #ffefde;
  --stf-orange-100: #fdd5b4;
  --stf-orange-200: #f7ba88;
  --stf-orange-300: #f19f5a;
  --stf-orange-400: #ec832c;
  --stf-orange-500: #d36a13;
  --stf-orange-600: #a5520d;
  --stf-orange-700: #763a07;
  --stf-orange-800: #482200;
  --stf-orange-900: #1d0a00; }

:root {
  --stf-yellow-50: #fff8de;
  --stf-yellow-100: #f9eab6;
  --stf-yellow-200: #f3dc8b;
  --stf-yellow-300: #eecf5e;
  --stf-yellow-400: #e9c131;
  --stf-yellow-500: #d0a719;
  --stf-yellow-600: #a18211;
  --stf-yellow-700: #735d09;
  --stf-yellow-800: #453802;
  --stf-yellow-900: #191300; }

:root {
  --stf-green-50: #e2fbed;
  --stf-green-100: #c2ebd4;
  --stf-green-200: #9fddb9;
  --stf-green-300: #7ccf9e;
  --stf-green-400: #58c184;
  --stf-green-500: #3ea76a;
  --stf-green-600: #2e8251;
  --stf-green-700: #1f5d3a;
  --stf-green-800: #0f3921;
  --stf-green-900: #001506; }

:root {
  --stf-teal-50: #defcfa;
  --stf-teal-100: #beefec;
  --stf-teal-200: #9ae1de;
  --stf-teal-300: #74d4d0;
  --stf-teal-400: #50c8c2;
  --stf-teal-500: #37afa9;
  --stf-teal-600: #278883;
  --stf-teal-700: #17625e;
  --stf-teal-800: #033b39;
  --stf-teal-900: #001616; }

:root {
  --stf-blue-50: #e0f4ff;
  --stf-blue-100: #b8dcfa;
  --stf-blue-200: #8ec4f1;
  --stf-blue-300: #63ace8;
  --stf-blue-400: #3994e0;
  --stf-blue-500: #1f7bc6;
  --stf-blue-600: #135f9b;
  --stf-blue-700: #084470;
  --stf-blue-800: #002946;
  --stf-blue-900: #000f1d; }

:root {
  --stf-cyan-50: #d9fdff;
  --stf-cyan-100: #adf1ff;
  --stf-cyan-200: #7fe7fb;
  --stf-cyan-300: #50dcf8;
  --stf-cyan-400: #24d2f5;
  --stf-cyan-500: #0ab9db;
  --stf-cyan-600: #0090ab;
  --stf-cyan-700: #00677c;
  --stf-cyan-800: #003f4c;
  --stf-cyan-900: #00171c; }

:root {
  --stf-purple-50: #f0e8ff;
  --stf-purple-100: #cfbcf8;
  --stf-purple-200: #b091ef;
  --stf-purple-300: #9065e7;
  --stf-purple-400: #7039df;
  --stf-purple-500: #5620c5;
  --stf-purple-600: #43189a;
  --stf-purple-700: #30116f;
  --stf-purple-800: #1d0944;
  --stf-purple-900: #0c011b; }

:root {
  --stf-pink-50: #ffe5f5;
  --stf-pink-100: #fab8d9;
  --stf-pink-200: #f38cbd;
  --stf-pink-300: #ec5fa3;
  --stf-pink-400: #e63389;
  --stf-pink-500: #cd1b6f;
  --stf-pink-600: #a01357;
  --stf-pink-700: #730b3e;
  --stf-pink-800: #470425;
  --stf-pink-900: #1d000e; }

.stf-default-theme {
  font-family: var(--stf-font-family);
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* Utilities */
  /* Button */
  /* Form group styles */
  /* Form control styles */
  /* Label styles */
  /* Form control message */
  /* Form group addons */
  /* Dropdowns */
  /* Select items */
  /* Radio button */
  /* Checkbox */
  /* Switch */ }
  .stf-default-theme .stf-flex {
    margin-top: 10px;
    display: flex; }
    .stf-default-theme .stf-flex.stf-flex-column {
      flex-direction: column; }
  .stf-default-theme .stf-form-button {
    border: 1px solid var(--stf-blue-500);
    background: var(--stf-blue-500);
    color: var(--stf-white);
    border-radius: 5px;
    font-weight: bold;
    transition: 0.25s;
    margin-top: 15px;
    font-size: 16px;
    outline: none;
    padding: 10px;
    width: 100%; }
    .stf-default-theme .stf-form-button:hover {
      cursor: pointer; }
    .stf-default-theme .stf-form-button:active {
      transform: scale(1.01); }
    .stf-default-theme .stf-form-button:disabled {
      opacity: 0.5; }
  .stf-default-theme .stf-form-group {
    display: inline-block;
    position: relative;
    padding-top: 15px;
    width: 100%; }
  .stf-default-theme .stf-form-control {
    border: 1px solid var(--stf-gray-100);
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    padding: 10px;
    resize: none;
    width: 100%; }
    .stf-default-theme .stf-form-control.invalid {
      color: var(--stf-red-500); }
    .stf-default-theme .stf-form-control.focus {
      border: 1px solid var(--stf-blue-500); }
    .stf-default-theme .stf-form-control.prepend {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px; }
    .stf-default-theme .stf-form-control.append {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px; }
  .stf-default-theme .stf-form-label {
    color: var(--stf-gray-800);
    display: block; }
    .stf-default-theme .stf-form-label.invalid {
      color: var(--stf-red-500); }
  .stf-default-theme .stf-form-text {
    padding: 10px 0px;
    font-size: 12px;
    display: block; }
    .stf-default-theme .stf-form-text.stf-text-muted {
      color: var(--stf-gray-500); }
    .stf-default-theme .stf-form-text.stf-text-invalid {
      color: var(--stf-red-500); }
  .stf-default-theme .stf-form-addon {
    border: 1px solid var(--stf-gray-200);
    background: var(--stf-gray-50);
    color: var(--stf-gray-700);
    justify-content: center;
    align-items: center;
    max-width: 50px;
    min-width: 50px;
    display: flex; }
    .stf-default-theme .stf-form-addon.prepend {
      border-radius: 5px 0px 0px 5px;
      border-width: 1px 0px 1px 1px; }
    .stf-default-theme .stf-form-addon.append {
      border-radius: 0px 5px 5px 0px;
      border-width: 1px 1px 1px 0px; }
  .stf-default-theme .stf-dropdown {
    border: 1px solid var(--stf-gray-200);
    background: var(--stf-white);
    position: absolute;
    visibility: hidden;
    overflow-y: scroll;
    max-height: 300px;
    width: 100%;
    z-index: 1; }
    .stf-default-theme .stf-dropdown.visible {
      visibility: visible; }
  .stf-default-theme .stf-dropdown-item {
    align-items: center;
    padding: 5px 0px;
    display: flex;
    padding: 10px; }
    .stf-default-theme .stf-dropdown-item:hover {
      background: var(--stf-gray-50);
      cursor: pointer; }
    .stf-default-theme .stf-dropdown-item.selected {
      background: var(--stf-blue-300);
      color: var(--stf-white); }
  .stf-default-theme .stf-radio {
    border: 1px solid var(--stf-gray-500);
    border-radius: 50%;
    margin-right: 8px;
    height: 15px;
    width: 15px;
    padding: 1px; }
    .stf-default-theme .stf-radio .filled {
      background: var(--stf-blue-500);
      border-radius: 50%;
      height: 100%;
      width: 100%; }
  .stf-default-theme .stf-checkbox {
    border: 1px solid var(--stf-gray-500);
    border-radius: 2px;
    margin-right: 8px;
    padding: 1px;
    height: 15px;
    width: 15px; }
    .stf-default-theme .stf-checkbox .filled {
      background: var(--stf-blue-500);
      border-radius: 2px;
      height: 100%;
      width: 100%; }
  .stf-default-theme .stf-switch {
    border: 1px solid var(--stf-gray-500);
    border-radius: 15px;
    position: relative;
    margin-right: 8px;
    transition: 0.25s;
    height: 15px;
    padding: 1px;
    width: 30px; }
    .stf-default-theme .stf-switch.actived {
      background: var(--stf-blue-500); }
    .stf-default-theme .stf-switch .pill {
      border: 1px solid var(--stf-gray-500);
      background: var(--stf-gray-50);
      height: calc(100% - 4px);
      border-radius: 50%;
      position: absolute;
      transition: 0.25s;
      width: 14px;
      left: 1px; }
      .stf-default-theme .stf-switch .pill.actived {
        left: 15px; }
